<template>
  <div class="addHoltel">
    <el-breadcrumb separator="/">
      <el-breadcrumb-item>添加酒店</el-breadcrumb-item>
    </el-breadcrumb>
    <el-tabs type="border-card" v-model="activeName">
      <el-tab-pane name="1" label="基本内容">
        <div class="content">
          <el-form
            ref="form"
            :model="formList"
            label-width="80px"
            :rules="step1Rules"
          >
            <el-form-item label="酒店名称"
              ><el-input v-model.trim="formList.hotelName"></el-input>
            </el-form-item>
            <el-form-item label="酒店分类" class="hotelcate">
              <el-select placeholder="请选择" v-model.trim="CateValue1">
                <el-option
                  v-for="item in hotelCateList1"
                  :key="item.cateId"
                  :label="item.cateName"
                  :value="item.cateId"
                >
                </el-option>
              </el-select>
              <el-select
                placeholder="请选择"
                v-model.trim="formList.hotelCateId"
              >
                <el-option
                  v-for="item in hotelCateList2"
                  :key="item.cateId"
                  :label="item.cateName"
                  :value="item.cateId"
                >
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="酒店标签" class="col start">
              <el-input v-model="formList.hotelLabel"> </el-input>
            </el-form-item>
            <el-form-item label="星级" class="fenshu">
              <el-select
                v-model.trim="startvalue"
                placeholder="请选择"
                @change="onStartChnange"
              >
                <el-option
                  v-for="item in startType"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                >
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="推荐理由">
              <el-input maxlength="26" v-model="formList.recommend"></el-input>
            </el-form-item>
            <el-form-item label="联系人" class="col">
              <el-input
                v-model.trim="formList.hotelLinkman"
                class="tel"
              ></el-input>
              <el-form-item
                label="客服电话"
                style="display: inline-block"
                class="col"
              >
                <el-input v-model="formList.hotelTel" class="tel"></el-input>
              </el-form-item>
            </el-form-item>

            <el-form-item label="所在街道" class="street">
              <el-select placeholder="请选择省" v-model.trim="provinceValue">
                <el-option
                
                  v-for="item in provinceList"
                  :key="item.name"
                  :label="item.name"
                  :value="item.code"
                >
                </el-option>
              </el-select>
              <el-select placeholder="请选择市" v-model.trim="cityValue">
                <el-option
                  v-for="item in cityList"
                  :key="item.name"
                  :label="item.name"
                  :value="item.code"
                >
                </el-option>
              </el-select>
              <el-select placeholder="请选择地区" v-model.trim="areaValue">
                <el-option
                  v-for="item in areaList"
                  :key="item.name"
                  :label="item.name"
                  :value="item.code"
                >
                </el-option>
              </el-select>
              <el-select placeholder="请选择街道" v-model="formList.streetCode"  @change="formatDEfaultAddress">
                <el-option
                  v-for="item in streetList"
                  :key="item.name"
                  :label="item.name"
                  :value="item.code"
                 
                >
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="详细地址" class="map">
               <my-map 
            @selectAddress="onAddressChange"
               @onMapClick="onMapClick"
                  :Address1="formList.hotelAddress"></my-map>
              <!-- <my-map @onAddressChange="onAddressChangeHandle"   :Address1="formList.address"></my-map> -->
            </el-form-item>
            <el-form-item label="纬度" class="adress">
              <el-input v-model="formList.hotelLat"></el-input>
              <span>经度</span>
              <el-input v-model="formList.hotelLon"></el-input
            ></el-form-item>
            <el-form-item label="简介">
              <el-input
                type="textarea"
                :rows="6"
                placeholder="请输入内容"
                v-model="formList.hotelSummary"
              >
              </el-input>
            </el-form-item>
          </el-form>
          <div class="btn">
            <el-button type="primary" @click="next('2')">下一步</el-button>
            <el-button type="primary" @click="$router.go(-1)">返回</el-button>
          </div>
        </div>
      </el-tab-pane>
      <el-tab-pane name="2" label="酒店图片">
        <div class="content pic">
          <el-form ref="form" label-width="80px">
            <el-form-item label="展示图">
              <send-image
                type="one"
                @addImageStr="(e) => addImageStr(e, 1)"
              ></send-image>

              <span style="color: red; margin-left: 20px"
                >每张图片不能超过500K，建议尺寸：335*172px</span
              >
            </el-form-item>
            <el-form-item label="分享图">
              <send-image
                type="one"
                @addImageStr="(e) => addImageStr(e, 2)"
              ></send-image>
              <span style="color: red; margin-left: 20px"
                >每张图片不能超过500K，建议尺寸：250*150px</span
              >
            </el-form-item>
            <el-form-item label="轮播图">
              <send-image type="more" @addImageStrs="addImageStrs"></send-image>
              <span style="color: red; margin-left: 20px"
                >每张图片不能超过500K，建议尺寸：345*187px</span
              >
            </el-form-item>
            <el-form-item label="PC轮播图" >
              <send-image type="morePc" :maxLength="4" @addImageStrsPc="addImageStrsPc"></send-image>
              <span style="color: red; margin-left: 20px"
                >每张图片不能超过500K，建议尺寸：1960*680px</span
              >
            </el-form-item>
          </el-form>
          <div class="btn">
            <el-button type="primary" style="margin-top: 12px" @click="pre('1')"
              >上一步</el-button
            >
            <el-button type="primary" @click="next('3')">下一步</el-button>
            <el-button type="primary" @click="$router.go(-1)">返回</el-button>
          </div>
        </div>
      </el-tab-pane>
      <el-tab-pane name="3" label="规则详情">
        <div class="content rules">
          <el-form ref="form" label-width="80px">
            <el-form-item label="预定规则">
              <el-input
                type="textarea"
                :rows="6"
                placeholder="请输入预定规则"
                v-model.trim="formList.hotelBookNotice"
              >
              </el-input>
            </el-form-item>
            <el-form-item label="退订规则">
              <div>
                离入住时间
                <el-input
                  type="number"
                  v-model="formList.hotelOverTime3"
                  placeholder=""
                ></el-input>
                小时免退订费用
              </div>
              <div>
                离入住时间
                <el-input
                  type="number"
                  v-model="formList.hotelOverTime1"
                  placeholder=""
                ></el-input>
                小时以上,手续费
                <el-input
                  type="number"
                  v-model="formList.hotelCharge1"
                  placeholder=""
                ></el-input>
                %
              </div>
              <div>
                离入住时间
                <el-input
                  type="number"
                  v-model="formList.hotelOverTime2"
                  placeholder=""
                ></el-input>
                小时以内,手续费
                <el-input
                  type="number"
                  v-model="formList.hotelCharge2"
                  placeholder=""
                ></el-input>
                %
              </div>
            </el-form-item>
            <el-form-item label="酒店详情">
              <!-- <quill-editor
                class="richEdit"
                v-model.trim="formList.hotelDetails"
                :options="quillOption"
                ref="myTextEditor"
              >
              </quill-editor> -->
               <VueUeditorWrap v-model="formList.hotelDetails" :config="myConfig" ref="myTextEditor"></VueUeditorWrap>
            </el-form-item>
            <el-form-item>
              <span style="color: red">建议尺寸750*1500px</span>
            </el-form-item>
          </el-form>
          <div class="btn">
            <el-button type="primary" style="margin-top: 12px" @click="pre('2')"
              >上一步</el-button
            >
            <el-button type="primary" @click="OnSave">保存</el-button>
            <el-button type="primary" @click="$router.go(-1)">返回</el-button>
          </div>
        </div>
      </el-tab-pane>
    </el-tabs>
  </div>
</template>
<script>
import { sendFile } from "../../api/sendFile";
import { hotelCateList, subClassification } from "../../api/hotel";
import { addHotel } from "../../api/hotelList";
import myMap from "../../components/map";
import quillConfig from "../../../quill-config";
import {
  getAllProvince,
  getCityByProvince,
  getAreaByCity,
  getStreetByArea,
} from "../../api/address";
import VueUeditorWrap from "vue-ueditor-wrap";
import SendImage from "../../components/sendImage.vue";
import { Quill } from "vue-quill-editor";

export default {
  name: "addHotel",
  components: {
    myMap: myMap,
    SendImage,
    VueUeditorWrap,
  },

  props: {
    id: {
      type: [Number, String],
    },
  },
  data() {
    const self = this;
    return {
       myConfig: {
        toolbars: [
          [
            'simpleupload', //单图上传
            "fullscreen",
            "source",
            "|",
            "undo",
            "redo",
            "|",
            "bold",
            "italic",
            "underline",
            "fontborder",
            "strikethrough",
            "superscript",
            "subscript",
            "removeformat",
            "formatmatch",
            "autotypeset",
            "blockquote",
            "pasteplain",
            "|",
            "forecolor",
            "backcolor",
            "insertorderedlist",
            "insertunorderedlist",
            "selectall",
            "|",
            "rowspacingtop",
            "rowspacingbottom",
            "lineheight",
            "|",
            "customstyle",
            "paragraph",
            "fontfamily",
            "fontsize",
            "|",
            "directionalityltr",
            "directionalityrtl",
            "indent",
            "|",
            "justifyleft",
            "justifycenter",
            "justifyright",
            "justifyjustify",
            "|",
            "touppercase",
            "tolowercase",
            "|",
            "imagenone",
            "imageleft",
            "imageright",
            "imagecenter",
            "|",
            "emotion",
            "map",
            "insertcode",
            "background",
            "|",
            "horizontal",
            "date",
            "time",
            "spechars",
            "|",
            "inserttable",
            "deletetable",
            "insertparagraphbeforetable",
            "insertrow",
            "deleterow",
            "insertcol",
            "deletecol",
            "mergecells",
            "mergeright",
            "mergedown",
            "|",
            "print",
            "preview",
          ],
        ],
        autoHeightEnabled: false, // 编辑器不自动被内容撑高
        initialFrameHeight: 500, // 初始容器高度
        initialFrameWidth: "100%", // 初始容器宽度
        // serverUrl: "/api/config",
        serverUrl: "/config",//打包
        // UEDITOR_HOME_URL: "/UEditor/",
        UEDITOR_HOME_URL: "/static/UEditor/",//打包
        maximumWords: "100000000",
      },
      quillOption: quillConfig,
      activeName: "1",
      hotelCateList1: [],
      hotelCateList2: [],
      CateValue1: "",
      CateValue2: "",
      provinceValue: "440000",
      cityValue: "440900",
      areaValue: "440923",
      streetValue: "",
      provinceList: [],
      cityList: [],
      cityList: [],
      areaList: [],
      streetList: [],
      dialogImageUrl: "",
      dialogVisible: false,
      startType: [
        {
          value: "5",
          label: "五星级",
        },
        {
          value: "4",
          label: "四星级",
        },
        {
          value: "3",
          label: "三星级",
        },
      ],
      startvalue: "",
      formList: {
        hotelName: "",
        hotelCateId: "",
        hotelLabel: "",
        hotelStartType: "",
        hotelScore: 4.9,
        hotelLinkman: "",
        streetCode: "",
        hotelAddress: "",
        hotelSummary: "",
        hotelShowImg: "",
        hotelLat: null,
        hotelLon: null,
        hotelTel: null,
        hotelBookNotice: "",
        hotelOverTime3: null,
        hotelOverTime1: null,
        hotelCharge1: null,
        hotelImg: "",
        hotelOverTime2: null,
        hotelCharge2: null,
        hotelDetails: "",
        recommend: "",
        shareImg: "",
        pcImg:"",
      },
      step1Rules: {
        hotelName: [
          { required: true, message: "请输入酒店名称", trigger: "blur" },
        ],
        hotelLabel: [
          { required: true, message: "请输入酒店便签", trigger: "blur" },
        ],
        hotelScore: [
          { required: true, message: "请输入酒店评分", trigger: "blur" },
        ],
      },
      tags: [],
    };
  },
  mounted() {
    quillConfig.register(Quill);
    quillConfig.initButton();
  },
  computed: {},
  watch: {
    CateValue1: async function () {
      console.log(this.CateValue1);
      this.formList.hotelCateId = "";
      const { data } = await subClassification({
        currentPage: 1,
        pageSize: 1000,
        fatherId: this.CateValue1,
      });
      // data.list.map((item) => {
      //   item.label = item.cateName;
      //   item.value = item.CateId;
      // });
      this.hotelCateList2 = data.data;
    },
    provinceValue: async function () {
      this.cityValue = "";
      this.areaValue = "";
      this.formList.streetCode = "";
      const { data } = await getCityByProvince({
        provinceCode: this.provinceValue,
      });
      this.cityList = data.data;
       this.formatDEfaultAddress()
    },
    cityValue: async function () {
      this.areaValue = "";
      this.formList.streetCode = "";
      const { data } = await getAreaByCity({
        cityCode: this.cityValue,
      });
      console.log(data.data);
      this.areaList = data.data;
       this.formatDEfaultAddress()
    },
    areaValue: async function () {
      this.formList.streetCode = "";
      const { data } = await getStreetByArea({
        areaCode: this.areaValue,
      });
      this.streetList = data.data;
      this.formatDEfaultAddress()
    },
  },
  created() {
    this.getHotelCateList();
    this.getAllProvinceList();
    this.getCityList();
    this.getareaList();
    this.getstreetList();
  },
  methods: {
     onMapClick({address,lat,lon}){
      this.formList.address = address
      this.formList.hotelLat = lat
      this.formList.hotelLon = lon

    },
    formatDEfaultAddress(){
      // 获取选择默认地址
       const pro = this.provinceList.filter(item=>{
       return item.code == this.provinceValue
      })
       const city= this.cityList.filter(item2=>{
       return item2.code == this.cityValue
      })
      const area = this.areaList.filter(item3=>{
       return item3.code == this.areaValue
      })
      const street = this.streetList.filter(item4=>{
       return item4.code == this.formList.streetCode
      })
      let addressArr = [...pro,...city,...area,...street]
      let defaultArr = []
      addressArr.forEach(item5=>{
        defaultArr.push(item5.name)
      })
     this.formList.hotelAddress =  defaultArr.join('')
     console.log( defaultArr.join(''),'defaultAddress')
    },
    onTag() {
      if (this.formList.hotelLabel == "") {
        return this.$message.warning("请输入内容");
      }
      this.tags = this.formList.hotelLabel.split(" ");
      console.log(this.tags);
    },
    handleClose(item) {
      this.tags = this.tags.filter((items) => {
        return item != item;
      });
    },
    handleInputConfirm() {},

    addImageStr(image, type) {
      if (type === 1) {
        this.formList.hotelShowImg = image;
      } else if (type === 2) {
        this.formList.shareImg = image;
      }
    },
    addImageStrs(image) {
      this.formList.hotelImg = image;
    },
    addImageStrsPc(image) {
      this.formList.pcImg = image;
    },
    async getCityList() {
      const { data } = await getCityByProvince({
        provinceCode: this.provinceValue,
      });
      this.cityList = data.data;
    },
    async getareaList() {
      const { data } = await getAreaByCity({
        cityCode: this.cityValue,
      });
      this.areaList = data.data;
    },
    async getstreetList() {
      const { data } = await getStreetByArea({
        areaCode: this.areaValue,
      });
      this.streetList = data.data;
    },
    onAddressChange({pname,cityname,adname,address,location}) {
        const { lat,lng} = location
      console.log(pname, cityname,lat,lng);
      this.formList.hotelAddress =  pname+cityname+adname+address;
      this.formList.hotelLon = lng;
      this.formList.hotelLat = lat;
    },
 
    async getHotelCateList() {
      const { data } = await subClassification({
        fatherId: 0,
      });
      // data.list.map((item) => {
      //   item.label = item.cateName;
      //   item.value = item.cateId;
      // });
      console.log(data, "1131231313");
      this.hotelCateList1 = data.data;
    },
    async getAllProvinceList() {
      const { data } = await getAllProvince();
      this.provinceList = data.data;
    },

    handleRemove(file, fileList) {
      this.formList.hotelShowImg = "";
    },
    handlePictureCardPreview(file) {
      this.dialogVisible = true;
    },
    successHandle(response) {
      this.formList.hotelShowImg = response.data[0].src;
    },
    maxLength() {
      this.$message({
        showClose: true,
        message: "最多只能上传1张图片",
        type: "warning",
      });
    },
    handlePictureCardPreviewMore() {
      this.dialogVisible = true;
    },
    handleRemoveMore(i) {
      console.log(i.response.data[0].src);
      this.formList.hotelImg = this.formList.hotelImg.filter((item) => {
        return item != i.response.data[0].src;
      });
      this.dialogImageUrl = "";
    },
    onTitleClick(active) {
      this.active = active;
    },
    successHandleMore(response) {
      this.formList.hotelImg.push(response.data[0].src);
    },
    maxLengthMore() {
      this.$message({
        showClose: true,
        message: "最多只能上传6张图片",
        type: "warning",
      });
    },
    onStartChnange() {
      this.formList.hotelStartType = Number(this.startvalue);
    },
    onStreetChange() {
      this.startvalue = Number(this.formList.streetCode);
    },
    async OnSave() {
      // console.log(this.formList)
      // return
      for (const key in this.formList) {
        if (this.formList[key] === null || this.formList[key] === "") {
          return this.$message({
            message: "请把数据填写完整",
            type: "error",
          });
        }
      }
      const { data } = await addHotel({
        hotelId: 0,
        hotelName: this.formList.hotelName,
        hotelCateId: Number(this.formList.hotelCateId),
        hotelLabel: this.formList.hotelLabel,
        hotelStartType: Number(this.formList.hotelStartType),
        hotelScore: Number(this.formList.hotelScore),
        hotelLinkman: this.formList.hotelLinkman,
        streetCode: this.formList.streetCode,
        hotelAddress: this.formList.hotelAddress,
        hotelSummary: this.formList.hotelSummary,
        hotelShowImg: this.formList.hotelShowImg,
        hotelLat: this.formList.hotelLat,
        hotelLon: this.formList.hotelLon,
        hotelTel: this.formList.hotelTel,
        hotelBookNotice: this.formList.hotelBookNotice,
        hotelOverTime3: Number(this.formList.hotelOverTime3),
        hotelOverTime1: Number(this.formList.hotelOverTime1),
        hotelCharge1: Number(this.formList.hotelCharge1),
        hotelImg: this.formList.hotelImg.join(","),
        hotelOverTime2: Number(this.formList.hotelOverTime2),
        hotelCharge2: Number(this.formList.hotelCharge2),
        hotelDetails: this.formList.hotelDetails,
        recommend: this.formList.recommend,
        pcImg: this.formList.pcImg.join(","),
        shareImg: this.formList.shareImg,
      });
      if (data.code === 0) {
        this.$message({
          message: "添加酒店成功",
          type: "success",
        });
        this.$router.go(-1);
      }
    },

    next(value) {
      this.activeName = value;
    },
    pre(value) {
      this.activeName = value;
    },
  },
};
</script>
<style lang="less" scoped>
.addHoltel {
  .el-breadcrumb {
    height: 35px;
    border-bottom: 1px solid rgb(192, 191, 191);
  }
  .el-steps {
    margin-top: 50px;
  }
  .content {
    // width: 900px;
    .adress {
      span {
        margin: 20px;
      }
      .el-input {
        width: 364px;
      }
    }
    .col {
      width: 400px;

      .el-select {
        width: 350px;
      }
      span {
        margin: 20px;
      }
      .tel {
        width: 354px !important;
      }
      .el-input {
        width: 355px;
      }
    }
    /deep/ .el-form {
      .el-form-item {
        .el-form-item__content {
          width: 800px;
        }
      }
      .hotelcate {
        .el-form-item__content {
          .el-select {
            width: 400px !important;
          }
        }
      }
      .map {
        .el-form-item__content {
          // height: 320px;
          .container {
            width: 800px;
            // height: 270px;
            .amap-container {
              // height: 270px;
            }
          }
        }
      }
      .street {
        .el-select {
          width: 200px;
        }
      }
      .fenshu {
        margin-left: 38px;
      }
      .start,
      .fenshu {
        width: 400px;
        display: inline-block;
        .el-form-item__content {
          width: 365px;
          .el-select {
            width: 360px;
          }
        }
      }
      .el-form-item__content {
        width: 500px;
        // .el-vue-amap-container {
        //   height: 300px;
        // }
        .richEdit {
          height: 500px;
          width: 800px;
          .ql-container {
            height: 400px;
          }
        }
      }
    }
  }
  .pic {
    /deep/.el-form {
      .el-form-item__content {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        width: 500px;
        height: 200px;
        .img {
          position: relative;
          height: 100px;
          width: 100px;
          margin: 0 20px;
          // border: 1.5px solid #c0ccda;

          img {
            height: 100%;
            width: 100%;
            // border: 1px solid #c0ccda;
          }
          div {
            position: absolute;
            top: 0;
            right: 5px;
            height: 10px;
            width: 10px;
            z-index: 100;
          }
        }
        .addImg {
          display: inline-block;
          width: 100px;
          height: 100px;
          border: 1px dashed #c0ccda;
          box-sizing: border-box;
          text-align: center;
          line-height: 100px;
          cursor: pointer;
          i {
            width: 50px;
            height: 50px;
            font-size: 20px;
            color: #c0ccda;
          }
        }
      }
    }
  }
  .rules {
    .el-form {
      .el-form-item__content {
        div {
          color: #606266;
          font-size: 16px;
          .el-input {
            width: 200px;
            height: 20px;
          }
        }
      }
    }
  }
  .btn {
    width: 400px;
    margin: 0 auto;
  }
}
</style>